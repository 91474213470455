// We still need some bootstrap utility methods
@import './scss/bootstrap.custom.scss';

@import './scss/colors.scss';
@import './scss/fonts.scss';
@import './scss/gradients.scss';
@import './scss/buttons.scss';

// Fixes all menues to display the whole value of the options.
// With small fields angular 16 crushes the options menu overlay
// element to be the same size of the parented
// field, in turn chrushing the content inside.
.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  min-width: fit-content;
}

// Fixes all menues to display the whole value of the options.
// With small fields angular 16 crushes the options menu overlay
// element to be the same size of the parented
// field, in turn chrushing the content inside.
.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  min-width: fit-content;
}

app-page.sim-report-small-screen {
  padding-left: 0px;
  padding-right: 0px;
}

app-fast-track-landing,
app-fast-track-pattern,
app-fast-track-product,
app-fast-track-user-info,
app-fast-track-region,
app-fast-track-explore {
  width: 100%;
  max-width: 1600px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: auto;
  @media (max-width: 1100px) {
    padding: 0 1em;
  }
}

app-fast-track-landing {
  height: 100%;
}

app-fast-track-product app-new-product-card {
  mat-card {
    height: 100%;
    min-height: 500px;
  }
}

app-fast-track-pattern > div > app-pattern-maker-card {
  height: 85%;
  max-height: 700px;
  display: block;
  .create-sections-wrapper {
    height: 100%;
  }
}

.landing-step-item-wrapper {
  margin: 3em 1em;
  width: 80%;
  max-width: 1600px;
  border-radius: 30px;
  padding: 2em;
  background: linear-gradient(90deg, #f4f5f9 0%, #e7e5e5 100%);
  max-height: 80vh;
  min-height: 650px;
  overflow: hidden;
  @media (max-width: 1100px) {
    width: 100%;
  }
  h1 {
    font-size: 26px;
    color: #666;
    font-family: $pally-font-primary;
    font-weight: 600;
    margin-bottom: 1em;
    margin-left: 0.5em;
  }
}

.pallet-dialog-container {
  max-width: 1200px !important;
  .mat-mdc-dialog-container {
    padding: 0;
  }
}

.userMainMenu + * .cdk-overlay-pane,
.orgMainMenu + * .cdk-overlay-pane {
  margin-top: 20px;

  .mat-mdc-menu-panel {
    background: #213153;
    max-width: none !important;
    width: 330px;

    .mat-mdc-menu-content button {
      span {
        color: rgba(255, 255, 255, 0.9);
        font-size: 16px;
        font-family: $pally-font-primary;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .mat-mdc-menu-content button mat-icon,
    .mat-mdc-menu-item-submenu-trigger::after {
      color: rgba(255, 255, 255, 0.9) !important;
    }
  }
}

.org-state-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  div:nth-child(1) {
    width: 104px;
    height: 104px;
    margin-right: 1em;
    border: 2px solid #d1d1d1;
  }
  div:nth-child(2) {
    h3:nth-child(1) {
      font-weight: normal;
      font-size: 12px;
    }
    h3:nth-child(2) {
      font-weight: normal;
      font-size: 24px;
    }
  }
}

.subheader-global {
  font-size: 20px;
  font-weight: 500;
  margin: 1.5em 0;
}

.transition {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.dts-select-container:not(.dts-custom) .dts-select-item.selected {
  border: none !important;
  box-shadow: none !important;
  background-color: $primary-light-gray;
  height: 100%;
  width: 100%;
  z-index: 99;
}

.alignMenu {
  position: absolute;
  top: 0;
}

.dts-select-box {
  background-color: $primary-background-transparent-50 !important;
  color: $primary-background !important;
}

.no_padding_dialog > mat-dialog-container {
  padding: 0;
}

mat-slide-toggle {
  div {
    label {
      margin-bottom: 0;
    }
  }
}

mat-checkbox {
  div {
    label {
      margin-bottom: 0;
    }
  }
}

mat-dialog-container {
  background-image: linear-gradient(
    to right bottom,
    #ffffff,
    #f8f8f8,
    #f1f1f1,
    #ebebeb,
    #e4e4e4
  ) !important;
}

app-verify,
app-user-dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: white;
  background-color: #c3c3c3;
}

app-simulations-dashboard,
app-user-dashboard {
  height: fit-content;
}

app-user-dashboard {
  min-height: 100vh;
}

.nextButtonBaseline {
  z-index: 99;
  position: fixed !important;
  bottom: 2em !important;
  border: 2px solid !important;
  right: 2em !important;
  padding: 0.5em 2em !important;
  font-size: 18px;
  font-weight: 200;
  mat-icon {
    margin-left: 10px;
  }
}

html,
body {
  font-family: $pally-font-primary;
}

h1,
h2 {
  font-family: $pally-font-secondary;
}

.mat-mdc-card {
  border-radius: 8px !important;
}

.mat-tab-label {
  font-family: $pally-font-primary !important;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: $primary !important;
  opacity: 1 !important;
}

.mat-mdc-tab-header {
  border-bottom: none !important;
}

::ng-deep .mat-mdc-unelevated-button > .mat-icon {
  width: 22px !important;
}

::ng-deep .mat-mdc-form-field,
.mat-mdc-card-title,
.mat-mdc-icon-button {
  font-family: $pally-font-primary !important;
}

::ng-deep .mat-mdc-card-title {
  color: var(--cardColor);
  font-size: 30px;
}

::ng-deep .mat-card__actions {
  padding: 0;
}

mat-hint {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}

h3,
h4,
h5,
h6,
p,
button {
  font-family: $pally-font-primary;
}

h1 {
  font-weight: 200;
}

h2 {
  font-weight: 300;
}

h3 {
  font-weight: 400;
}

h4 {
  font-weight: 500;
}

h5,
h6 {
  font-weight: 700;
}

body {
  background-color: $primary-light-gray;
}

.wrapper {
  margin-top: 5em;
}

.content {
  width: 90%;
  margin: auto;
}

button:focus,
button:focus,
button:active {
  outline: none !important;
}

.snackbar-white-font {
  color: $primary-white;
  white-space: pre-line;
}

.snackbar-red-background {
  background-color: $primary-red !important;
  color: $primary-white !important;
  white-space: pre-line;
}

.snackbar-red-background .mat-mdc-snack-bar-action {
  color: $primary-white !important;
}

svg {
  vertical-align: top;
}

/////////////////////
// Utility classes //
/////////////////////
.flex {
  flex: 1;
}

.pally-version {
  background: radial-gradient(100% 115.7% at 0% 0%, #3e62ae 0%, #2b4478 100%);

  > img {
    position: absolute;
    top: 10%;
    left: 5%;
  }
  .version-text {
    position: absolute;
    max-height: 40%;
    inline-size: 90%;
    white-space: normal;
    right: 5%;
    bottom: 5%;
    font-family: $pally-font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    text-align: right;
    overflow-wrap: anywhere;
    overflow: hidden;
    color: white;
  }
}

.mat-card-pdf {
  border-radius: 12px;
  border: solid 2px lightgray;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;

  &.default-sim,
  &.error-sim,
  &.success-sim {
    --cardColor: rgba(247, 247, 251, 0.96);
  }

  ::ng-deep .mat-mdc-card-subtitle {
    color: var(--cardColor);
    font-size: 12px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $pally-font-primary;
    font-weight: 500;
    letter-spacing: 1.25px;
    span {
      font-size: medium;
    }
  }
}

.pallet-report-layout {
  /* These are here to render a thicker border for tables in the PDF. Due to a rendering issue in Chrome */
  app-table td.mat-mdc-cell,
  app-table th.mat-mdc-header-cell,
  app-table td.mat-mdc-footer-cell {
    border-bottom-width: 2px;
    border-color: lightgray;
  }

  app-table > .table-wrapper > .table-container {
    border-width: 2px !important;
    border-color: lightgray !important;
  }
}

.simulation-report-layout {
  .pdf-hide {
    display: none;
  }
  display: none;

  /* These are here to render a thicker border for tables in the PDF. Due to a rendering issue in Chrome */
  app-table td.mat-mdc-cell,
  app-table th.mat-mdc-header-cell,
  app-table td.mat-mdc-footer-cell {
    border-bottom-width: 2px;
    border-color: lightgray;
  }

  app-table > .table-wrapper > .table-container {
    border-width: 2px !important;
    border-color: lightgray !important;
  }
}
// Style for removing Lastpass icon on inputfields
[data-lastpass-icon-root='true'] {
  display: none;
}

.infoPopupButton {
  background: none;
  border: none;
  width: 40px;
  height: 30px;

  mat-icon {
    width: 20px;
    height: 20px;
    color: $primary;
  }
}

.license-status-active {
  color: #f7f7fb;
  background-color: #72b5b3;
  border-radius: 100px;
  width: 150px;
  padding: 6px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

mat-tab-header.mat-mdc-tab-header {
  .mdc-tab__text-label {
    color: #2b4478;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 600px) {
  .landing-step-item-wrapper {
    width: 99%;
  }
}

button.mat-mdc-icon-button {
  height: 48px !important;
}

mat-form-field.mat-mdc-form-field {
  .mat-mdc-form-field-infix {
    padding-bottom: 8px !important;
    margin-top: 8px;
  }
}

mat-form-field[app-minimalistic-border].mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    background-color: transparent !important;
    padding: 0 !important;
  }

  .mat-mdc-form-field-infix {
    padding-bottom: 8px !important;
    padding-top: 24px !important;
  }

  .mat-mdc-form-field-focus-overlay {
    display: none !important;
  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: #0000001f !important;
  }
}

.mat-mdc-paginator {
  color: rgba(0, 0, 0, 0.54) !important;
}

mat-panel-title {
  font-size: 15px;
  font-weight: 400;
}

.stepper-wrapper {
  width: 100%;
  margin: auto;
  margin-bottom: 2em;

  .mat-horizontal-content-container {
    padding: 1em;
    background: #f1f1f1;
  }
}